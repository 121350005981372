<template>
  <div class="message">、
    <top-bar :title="'群发短信'" :left="true"></top-bar>
      <van-cell-group v-if="street!=16724">
       <div class="headTitle">
        <div class="item"><p class="item-img"><img src="@/assets/img/ms_icon_1.png" alt=""></p><p class="title">短信剩余总量</p><p class="num">{{remindCount|playvolume}}</p></div>
        <div class="item"><p class="item-img"><img src="@/assets/img/ms_icon_2.png" alt=""></p><p class="title">每日可发送</p> <p v-if="dailyCount==-1" class="num">不限</p><p v-else class="num">{{dailyCount|playvolume}}</p></div>
        <div class="item"><p class="item-img"><img src="@/assets/img/ms_icon_3.png" alt=""></p><p class="title">今日发送</p>  <p class="num">{{todaySendCount|playvolume}}</p></div>
        <div class="item"><p class="item-img"><img src="@/assets/img/ms_icon_4.png" alt=""></p><p class="title">今日剩余</p>   <p v-if="dailyCount == -1" class="num">不限</p> <p v-else class="num">{{(dailyCount - todaySendCount>0?dailyCount - todaySendCount:0)|playvolume}}</p></div>
      </div>
    </van-cell-group>
    <van-cell-group>
      <van-cell>
      <template #title>
        <div class="blue-box"></div>
        <span class="custom-title">群发人员</span>
      </template>
      </van-cell>
      <van-cell title="机构名称" :value="selectMsg.org" @click="orgShow = true"
                  :value-class="{'value-common':selectMsg.org=='请选择'}" is-link/>
      <van-cell title="小区名称" :value="selectMsg.community" @click="communityShow = true"
                 v-if="submitData.orgIndex == '1'"
                :value-class="{'value-common':selectMsg.community=='请选择'}" is-link/>

      <van-cell title="楼幢名称" :value="selectMsg.building" @click="buildingShow = true"
                v-if="submitData.orgIndex == '1'"
                :value-class="{'value-common':selectMsg.building=='请选择'}" is-link/>

      <van-cell title="单元名称" :value="selectMsg.unit" @click="unitShow = true"
               v-if="submitData.orgIndex == '1'"
                :value-class="{'value-common':selectMsg.unit=='请选择'}" is-link/>

      <van-cell readonly clickable title="标签"   :value="selectMsg.tag"
                :value-class="{'value-common':selectMsg.tag=='请选择'}" is-link @click="labelShow=true"/>
       <van-cell readonly clickable  style="display:flex">

          <van-radio-group v-model="radio" direction="horizontal">
                   <span style="width:30%">发送方式</span>
                   <van-radio name="0">新短信</van-radio>
                   <van-radio name="1" @click="branchs=true">补充发送</van-radio>
          </van-radio-group>
       </van-cell>
      <van-popup :value="branchs" @click-overlay="branchs=false;appSendBatch='';radio='0'" :style="{height:'30%', width:'80%', padding: '0 15px'}" round>
        <van-cell-group >
        <van-field v-model="appSendBatch"  rows="4" :autosize="{ maxHeight: 100,}" type="text" placeholder="请输入发送批号" class="left"
          style="border-radius: 10px;padding: 10px 20px 0;margin-top: 10px;font-size: 14px"/>
        </van-cell-group>
         <van-button type="info" size="normal" :style="{width:'80%',height:'45px',margin:'70px 0 0 10%', textAlign:'center'}" round @click="submit">确认发送</van-button>
      </van-popup>
      <van-popup v-model="labelShow" position="bottom"
                  @click-overlay="labelOverlay">
        <van-cascader
            title="请选择"
            value="value" :field-names="{ text: 'label', value: 'value', children: 'children' }"
            :options="userCommunityLabelTree" active-color="#1989fa"
            @close="labelShow=false"
            @change="labelFinish"
        />
      </van-popup>
        <van-cell hover-class="none" v-if="labels.length>0">
          <template v-for="item in labels" >
            <!--                <van-tag class="vanTag" type="primary" v-if="item.rightShow && !item.delete" :key="item.id"-->
            <!--                         :closeable="(!control.disabled) && item.rightUpdate" size="medium"-->
            <!--                         @close="removeLabel(item.value)">{{ item.name }}-->
            <!--                </van-tag>-->
            <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
              <span >{{ item.name }}</span>
              <van-icon style="padding: 0 10px" name="cross" @click="removeLabel(item.value);countUsers()"/>
            </div>
          </template>
        </van-cell>
      </van-cell-group>

      <van-popup v-model="orgShow" position="bottom" @close="init()">
        <van-cascader title="请选择" :options="selectData.orgList" :field-names="fieldNames" v-model="orgValue"
                       @change="orgConfirm" @finish="orgShow = false" @close="orgShow = false"/>
      </van-popup>

      <van-popup v-model="communityShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.communityList" value-key="name"
                    @confirm="communityConfirm" @cancel="communityShow = false"/>
      </van-popup>

      <van-popup v-model="buildingShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.buildingList" value-key="label"
                    @confirm="buildingConfirm" @cancel="buildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"
                    @confirm="unitConfirm" @cancel="unitShow = false"/>
      </van-popup>



    <!-- 短信内容 -->
    <van-cell-group class="content" v-if="(submitData.orgIndex == '0' || submitData.orgIndex != '1' || isTasks)&& radio=='0'">
      <div  class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;人</div>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">群发内容</span>
          </template>
        </van-cell>
        <span  class="ms-title" style="padding-left:15px;display:inline-block">【{{this.$globalData.userInfo.streetName}}】</span>
        <van-field v-model="selectMsg.description" rows="3" autosize type="textarea" placeholder="请输入短信内容" class="left"/>
      </van-cell-group>
      <div class="des" style="padding:10px" v-if="street!=16724">
          <p class="top">短信未超过70字算一条，超过70字，则每67字算一条。</p>
          <p class="top">当前已用字数：{{num}}字，合{{strip}}条短信。</p>
          <p class="bottom">(最终发送结果请到《短信通》发送记录页面进行查询)</p>
      </div>
    </van-cell-group>

    <!-- 弹框 -->
    <van-popup v-model="show" :style="{height:'50%', width:'80%',padding: '0 15px'}" round>
      <span class="close" @click="close">&nbsp;×&nbsp;</span>
      <div class="totalCounts">共查出&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;人</div>
      <van-cell-group >
        <van-cell>
          <!-- <template #title>
            <span class="custom-title">群发内容</span>
          </template> -->
       </van-cell>
       <p v-if="street!=16724" class="ms-title">【{{this.$globalData.userInfo.streetName}}】<span class="title-text">已用字数：{{num}}字,合{{strip}}条短信.</span></p>
      <van-field v-model="selectMsg.description"  rows="4" :autosize="{ maxHeight: 100,}" type="textarea" placeholder="请输入短信内容" class="left"/>
      <div class="des" v-if="street!=16724">
          <p class="top">短信未超过70字算一条，超过70字，则每67字算一条。</p>
          <!-- <p class="top">当前已用字数：{{num}}字，合{{strip}}条短信。</p> -->
          <p class="bottom">(最终发送结果请到《短信通》发送记录页面进行查询)</p>
      </div>
      </van-cell-group>
       <van-button type="info" size="normal" :style="{width:'80%',height:'45px',margin:'30px 0 0 10%', textAlign:'center'}" round @click="submit">确认发送</van-button>
    </van-popup>

    <!-- 超出100条弹窗 -->
     <!-- <van-popup v-model="exceed" :style="{height:'20%', width:'80%', padding: '0 15px'}" round>
        <p style="text-align:center;font-size:16px;color:red">此短信发送超过短信剩余总量，超出部分将发送失败，是否确定发送？</p>
       <van-row style="padding-top:20px">
         <van-col align="center" :span="12"><van-button round @click="exceed=false">取消</van-button> </van-col>
         <van-col align="center" :span="12"><van-button round style="" type="info" @click="submits">确认</van-button></van-col>
         </van-row>
      </van-popup> -->

    <van-row class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" v-if="submitData.orgIndex == '0' || submitData.orgIndex != '1' || isTasks " round @click="submit">确认发送</van-button>
        <van-button type="info" size="large" v-if="submitData.orgIndex  == '1'" round @click="countUsers(true)">查询结果</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from "../../components/topBar/topBar.vue"
import {listComRightLabel} from "@/utils/common"
import {getBelongCommunity} from "@/utils/common";
export default {
  components:{
    topBar
  },
  data(){
    return{
       dailyCount: 0,
       remindCount: 0,
       todaySendCount: 0,
      userCommunityLabelTree: [],
      // 街道总人数
      totalCount:0,
      // 查询人数
      totalCount:0,
      // 查询结果
      show:false,
      // 确认发送
      shows:false,
      isTasks:false,
      orgValue:'',
      orgShow:false,
      communityShow:false,
      buildingShow:false,
      unitShow:false,
      temporaryLabel: [],//临时标签
      // 标签
      labels:[],
      labelShow: false,
      disabled: false,
      radio:'0', //选择发送方式
      appSendBatch:'',//批号
      branchs:false,
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      selectMsg:{
        org: '',
        community: '请选择',
        building: '请选择',
        unit: '请选择',
        tag: '请选择',
        description: ''
      },
      submitData: {
        orgStr:this.$orgId,
        communityStr:'',
        buildingStr: '',
        unitStr:'',
        labelStr:'',
        contentStr:'',
        orgIndex: '',
      },
      // 数据
      selectData:{
        orgList:[],
        communityList:[],
        buildingList:[],
        unitList:[],
        tagList:[],
      },
      num:this.$globalData.userInfo.streetName.length+2,
      strip:1,
      exceed:false,
      street:this.$globalData.userInfo.street
    }
  },
   watch:{
    'selectMsg.description'(val){
         if ((val.length+this.$globalData.userInfo.streetName.length+2)<=70) {
           this.num = val.length+this.$globalData.userInfo.streetName.length+2
           this.strip = 1
        }else if ((val.length+this.$globalData.userInfo.streetName.length+2)>70) {
          //  let end = this.selectMsg.description
          //  let strArr = [];
          //  let n = this.num<=70?70-(this.$globalData.userInfo.streetName.length+2):67-(this.$globalData.userInfo.streetName.length+2);
          // for (var i = 0, l = end.length; i < l/n; i++) {
          //  let a = end.slice(n*i, n*(i+1));
          //  strArr.push(`【${this.$globalData.userInfo.streetName}】`+a);
          //  }
          // this.strip = Math.ceil(strArr.join('').length/67)
          // this.num = strArr.join('').length
          this.num = val.length+this.$globalData.userInfo.streetName.length+2
          this.strip = Math.ceil((val.length+this.$globalData.userInfo.streetName.length+2)/67)
        }
    }
  },
  filters:{
    playvolume:function(arg){
      if(arg){
        if(arg.toString().length>=8){
          const volume= arg/10000000
          const realVal = parseFloat(volume).toFixed(2);
          return realVal+"千万"
        }else if(arg.toString().length>=7){
          const volume= arg/1000000
          const realVal = parseFloat(volume).toFixed(2);
          return realVal+"百万"
        }else if(arg.toString().length>4){
          const volume= arg/10000
          const realVal = parseFloat(volume).toFixed(2);
          return realVal+"万"
        }else if(arg.toString().length<5){
          const realVal = arg;
          return realVal
        }
      }
		}
  },
  created () {
    this.init()
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId,
          id: this.$globalData.userInfo.userId,
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          getBelongCommunity(this.$orgId, -1, (orgList) => {
            this.selectData.orgList = [{
              label: data.userManager.streetName,
              value: data.userManager.street,
              children: orgList
            }]
            this.selectMsg.org = data.userManager.streetName
            // this.selectData.orgList
            // this.selectData.orgList.unshift({
            //   label: this.$globalData.userInfo.streetName,
            //   value: Number(this.orgId)
            // })
          })
        } else {
        this.$toast.fail(data.msg);
        }
      })
      this.getsmsValue()
  },
  methods:{
    init(){
      let that = this
      listComRightLabel(function (labels) {
        that.userCommunityLabelTree = labels
      })
      // 初始统计
      this.countUsers(false)
    },
     // 短信数量
     getsmsValue () {
        this.$httpBack({
          url: this.$httpBack.adornUrl('/app/sms/send/records/uht/getOrgSmsInfo'),
          method: 'get',
          params: this.$httpBack.adornParams({
            orgId: this.$globalData.userInfo.orgId
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dailyCount = data.orgSms.dailyCount
            this.remindCount = data.orgSms.remindCount
            this.todaySendCount = data.orgSms.todaySendCount
          } else {
            this.$message.error(data.msg)
          }
        })
      },
    // 提交
    submit(){
      this.shows = true
      if(this.selectMsg.description === ''&&this.radio=='0'){
        return this.$toast.fail('请先输入短信内容')
      }
      //  if (this.strip > this.remindCount&&this.street!=16724) return this.exceed = true
      if (this.radio=='1'&& this.appSendBatch=='') return this.$toast.fail('请填写批号')
      if (this.totalCount==0) return this.$toast.fail('请选择发送人')
      this.submitData.contentStr = this.selectMsg.description
      this.$toast.loading({duration: 0, message: '提交中...', forbidClick: true,});
      this.submits()
    },
    submits(){
          this.$http({
          url: this.$http.adornUrl('/wxapp/sendNote/sendSms'),
          method: 'post',
          params:this.$http.adornParams({
          sendNoteSource: 3,
          orgId:this.submitData.orgStr,
          buildingIds:this.submitData.buildingStr,
          unitId:this.submitData.unitStr,
          labels:this.selectData.tagList.toString(),
          sendNoteContent:this.submitData.contentStr,
          labelCondition: 1,
          forSms:1,
          isAll:1,
          appSendBatch:this.appSendBatch
          })
        }).then(({data}) => {
          this.branchs=false
          if (data.code == 0) {
            console.log(data);
            this.$toast.success('发送成功');
          } else {
            this.$toast.fail(data.msg);
          }
        })
        this.close()
        this.exceed=false
        this.selectMsg.description = ''
      },
    // 查询结果显示
    countUsers(showDialog){
      // this.selectMsg.description = ''
      this.show = showDialog
      this.$http({
        url:this.$http.adornUrl('/wxapp/user/res/list'),
        method:'post',
        params: this.$http.adornParams({
          orgId:this.submitData.orgStr,
          buildingIds:this.submitData.buildingStr,
          unitId:this.submitData.unitStr,
          labels:this.selectData.tagList.toString(),
          labelCondition: 1,
          forSms:1,
          page:1,
          limit:1
        },false)
      }).then(({data}) =>{
        if(data.code === 0){
          console.log(data);
            this.totalCount = data.page.totalCount
        }else{
          this.$toast.fail(data.msg);
        }
      })
    },
    // 关闭弹窗
    close(){
      this.show = false
    },
    // 机构选择
    orgConfirm(value){
      console.log(value);
      this.submitData.communityStr=''
      this.submitData.buildingStr=''
      this.submitData.unitStr=''
      this.selectMsg.community=''
      this.selectMsg.building=''
      this.selectMsg.unit=''
      if (value) {
      //  let that = this
        // this.tabIndex = value.tabIndex
        this.submitData.orgIndex = value.tabIndex
        this.submitData.orgStr = value.selectedOptions[value.selectedOptions.length - 1].value
        this.selectMsg.org = value.selectedOptions[value.selectedOptions.length - 1].label
        this.$http({
          url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
          method: 'post',
          params: this.$http.adornParams({
            communityId: value.value
          },false)
        }).then(({data})=> {
            if (data.code == 0) {
              this.selectData.communityList = data.subAreaList
            } else {
            this.$toast.fail(data.msg);
            }
        })
      }
    },
    // 小区选择
    communityConfirm(value){
      console.log(value);
      this.submitData.buildingStr=''
      this.submitData.unitStr=''
      this.selectMsg.building=''
      this.selectMsg.unit=''
      if(value){
        this.submitData.communityStr = value.id
        this.selectMsg.community = value.name
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
          method: 'get',
          params:this.$http.adornParams({
            subArea:value.id
          })
        }).then(({data}) => {
          if (data.code == 0) {
            console.log(data);
           this.selectData.buildingList = data.buildings
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
      this.communityShow = false
    },
    // 楼幢选择
    buildingConfirm(value){
      console.log(value);
      this.submitData.unitStr=''
      this.selectMsg.unit=''
      if(value){
        this.submitData.buildingStr = value.value
        this.selectMsg.building = value.label
        this.$http({
          url:this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
          method:'get',
          params: this.$http.adornParams({
              buildingId: value.value
            },false)
        }).then(({data})=>{
          debugger
          if (data.code == 0) {
            console.log(data);
            this.selectData.unitList = data.units
          } else {
          this.$toast.fail(data.msg);
          }
        })
      }
      this.buildingShow = false
    },
    // 单元选择
    unitConfirm(value){
      if(value){
        this.submitData.unitStr = value.value
        this.selectMsg.unit = value.label
      }
      this.unitShow = false
    },

    // 标签显示处理
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    labelFinish(e) {
      console.log(e);
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
       let Label =this.formatLabel(map, false)
       this.temporaryLabel = Label.map(item => {
          let LabelIds = item.ids.toString().split(',')
           return {...item, ids: LabelIds[LabelIds.length - 1]}
          })
    },
    removeLabel(value) {
      // debugger
      for (let i in this.selectData.tagList) {
        let label = this.selectData.tagList[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          this.selectData.tagList.splice(i, 1)
        }
      }

      for (let i in this.labels) {
        let label = this.labels[i]
        if (label.value == value) {
          this.labels.splice(i,1)
        }
      }
      this.selectData.tagList = this.selectData.tagList
      this.labels = this.labels
      this.$forceUpdate()
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = this.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in this.labels) {
        let label = this.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }
      temporaryLabel["delete"] = false
      this.labels.push(temporaryLabel)
      this.selectData.tagList.push(temporaryLabel.ids)
      this.countUsers()
    }

  }

}
</script>

<style scoped lang="scss">
  .info{
    display: block;
  }
  .totalCounts{
    text-align: center;
    margin: 30px 0;
    font-size: 28px;
    color: rgba(234, 71, 74);
    font-weight: 600;
  }
  .close{
    display: block;
    font-size: 30px;
    position: absolute;
    /* font-weight: 600; */
    right: 16px;
    top: 10px;
  }
  .vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.ms-title{
    font-size: 24px;
  }
.headTitle{
    display: flex;
    margin-bottom: 30px;
    .item{
        flex: 1;
        text-align: center;
        font-size: 24px;
       .title{
          color: #666666;
        }
      .item-img{
        text-align: center;
        // width: 60px;
        img{
          width: 60px;
          height: 60px;
        }
      }
      .num{
        color: #333333;
        font-weight: 700;
      }
    }
  }
  ::-webkit-scrollbar{
    display: none;
  }
  .des {
  font-size: 20px;
    .top {
      color: #ED4747;
      // margin: 0;
    }
    .bottom {
    margin: 0;
  }
}
.title-text{
  display: inline-block;
  padding-left: 30px;
  color: #ED4747;
}
</style>
